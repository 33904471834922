<template>
  <el-card class="card-box"
           style="padding-right:160px">
    <el-form ref="form"
             :model="form"
             :hide-required-asterisk="true"
             :rules="rules"
             label-width="80px"
             :size="'mini'"
             :label-position="'top'">
      <el-row :gutter="90">
        <el-col :span="12"
                v-if="role(['administrator','manager'])">
          <el-form-item label="名字："
                        prop="name">
            <el-input v-model="form.name"
                      placeholder="请填写名字"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12"
                v-if="role(['administrator','manager'])">
          <el-form-item label="电话："
                        prop="phone">
            <el-input v-model="form.phone"
                      placeholder="请填写电话"
                      maxlength="11"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12"
                v-if="role(['administrator','manager'])">
          <el-form-item label="邀请人："
                        prop="inviter_id">
            <el-select class="width100"
                       filterable
                       clearable
                       v-model="form.inviter_id"
                       placeholder="请选择邀请人">
              <el-option v-for="item in inviterList"
                         :key="item.key"
                         :label="item.title"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12"
                v-if="role(['administrator','manager'])">
          <el-form-item label="分成比(%)："
                        prop="separate_ratio">
            <el-input-number class="width100"
                             v-model="form.separate_ratio"
                             :controls="false"
                             controls-position="right"
                             :precision="0"
                             :min="0"
                             :max="100"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12"
                v-if="role(['administrator'])">
          <el-form-item label="审核："
                        prop="status">
            <el-select class="width100"
                       v-model="form.status"
                       placeholder="请选择审核状态">
              <el-option label="待审核"
                         :value="0"></el-option>
              <el-option label="通过"
                         :value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12"
                v-if="role(['administrator'])">
          <el-form-item label="支付宝账号："
                        prop="alipay_account">
            <el-input v-model="form.alipay_account"
                      placeholder="请填写支付宝账号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12"
                v-if="role(['administrator'])">
          <el-form-item label="角色："
                        prop="role">
            <el-select class="width100"
                       v-model="form.role"
                       placeholder="请选择角色">
              <el-option v-for="item in roleList"
                         :key="item.key"
                         :label="item.title"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12"
                v-if="role(['administrator','manager'])">
          <el-form-item label="密码："
                        prop="password">
            <el-input v-model="form.password"
                      placeholder="请填写密码"
                      type="password"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12"
                v-if="role(['administrator','manager'])">
          <el-form-item label="确认密码："
                        prop="password_confirmation">
            <el-input v-model="form.password_confirmation"
                      placeholder="请填写确认密码"
                      type="password"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-button type="primary"
                   @click="submit">确定</el-button>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
import api from "@/api/index";
export default {
  props: {
    formEdit: {
      type: Object,
      default: () => { },
    },
  },
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.password_confirmation !== "") {
          this.$refs.form.validateField("password_confirmation");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        id: "",
        name: "",
        phone: "",
        inviter_id: "",
        alipay_account: "",
        password: "",
        password_confirmation: "",
        separate_ratio: 0,
        role: "",
        status: 0,
      },
      rules: {
        name: [{ required: true, message: "请输入名字", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入电话", trigger: "blur" },
          { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
        ],
        password: [
          { validator: validatePass, required: true, trigger: "blur" },
          { min: 6, message: "密码至少为6位", trigger: "blur" },
        ],
        password_confirmation: [
          { validator: validatePass2, required: true, trigger: "blur" },
          { min: 6, message: "密码至少为6位", trigger: "blur" },
        ],
        role: [{ required: true, message: "请选择角色", trigger: "change" }],
      },
      inviterList: [],
      roleList: [],
    };
  },
  created () {
    this._initState();
    //编辑
    if (this.formEdit) {
      for (let key in this.form) {
        this.form[key] = this.formEdit[key];
      }
      if (this.form["inviter_id"] === "--") {
        this.form["inviter_id"] = "";
      }
      if (this.form["separate_ratio"] === "--") {
        this.form["separate_ratio"] = 0;
      }
    }
  },
  methods: {
    async _initState () {
      const data = await api.brokerRolesApi();
      const { status_code, message } = await api.brokerInviterAddApi();
      if (status_code === 200) {
        this.inviterList = message;
        this.roleList = data.message;
      }
    },
    async admitFunc (apifunc, formObj, path, message) {
      const data = await api[apifunc]({ ...formObj });
      if (data.status_code === 200) {
        this.$message({
          type: "success",
          message: message + "成功",
        });
        this.$router.push({ path });
        this.$refs.form.resetFields();
      } else {
        if (typeof data.message === "object") {
          Object.keys(data.message).forEach((item) => {
            this.$message.error({ message: data.message[item] });
          });
        } else {
          this.$message.error({ message: data.message });
        }
      }
    },
    submit () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.formEdit) {
            console.log(this.form)
            const {
              name,
              phone,
              inviter_id,
              separate_ratio,
              password,
              password_confirmation,
              id
            } = this.form;
            if (this.role(["manager"])) {
              this.admitFunc(
                "brokerUpdateBrokerInfoApi",
                {
                  name,
                  phone,
                  inviter_id,
                  separate_ratio,
                  password,
                  password_confirmation,
                  id
                },
                "/agentmanage/agentlist",
                "修改"
              );
            } else {
              this.admitFunc(
                "brokerUpApi",
                this.form,
                "/agentmanage/agentlist",
                "修改"
              );
            }
          } else {
            this.admitFunc(
              "brokerCreateApi",
              this.form,
              "/agentmanage/agentlist",
              "添加"
            );
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    selectTreeChange (id) {
      this.form.inviter = id;
    },
  },
};
</script>

<style lang="less" scoped>
.width100 {
  width: 100%;
}
.card-box /deep/ .el-input--mini .el-input__inner {
  line-height: 40px;
  height: 40px;
}
.card-box /deep/ .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-itemP {
  margin-bottom: 33px;
}
.card-box /deep/ .el-input-number .el-input__inner {
  text-align: left;
}
</style>